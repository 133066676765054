.panel-heading.mb-20 {
    margin-bottom: 20px;
}

.banner-logo {
    height: 80px;
}

.signature_bottom {
    margin-bottom: -20px;
}

.nomi-Sign__cancel_bill {
    cursor: pointer;
    position: relative;
    right: 15px;
    top: 5px;
}
.nomi-Sign__cancel {
    cursor: pointer;
}

.nomi-Sign__cancel_president {
    cursor: pointer;
    position: relative;
    left: 25px;
    bottom: 10px;
}
 
.nomi-Sign__cancel_account {
    cursor: pointer;
    position: relative;
    left: 10px;
    bottom: 15px;
}
.nomi-Sign__cancel_bill:hover .add-text {
    display: inline;
}
.nomi-Sign__cancel:hover .add-text {
    display: inline;
}

.nomi-Sign__cancel_president:hover .add-text {
    display: inline;
}

.nomi-Sign__cancel_account:hover .add-text {
    display: inline;
}

.agreement-document {
    padding-left: 40px;
    padding-right: 40px
}

.agreement-document.flex-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.agreement-document.flex-center {
    display: flex;
    align-items: center;
}

.wide-input {
    width: 60%;
}

.signature-placeholder {
    width: 130px;
    height: 30px;
}

.ml-23 {
    margin-left: 23px;
}

.date-input {
    width: 30%;
}

.padding-l15.flex-center {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.avatar__holder {
    width: 130px;
    height: 30px;
}

.ml-10 {
    margin-left: 10px;
}

.ml-5 {
    margin-left: 5px;
}

.no-list-style {
    list-style-type: none;
}

.inline-block {
    display: inline-block;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
}

.pt-80 {
    padding-top: 80px;
}

.margin-b0 {
    margin-bottom: 0;
}

.padding-l30 {
    padding-left: 30px;
}

.president-section {
    padding-left: 352px;
}

.footer-hr {
    border-top: 1px solid #c7bfbf;
}

.page-number {
    margin-left: auto;
}

