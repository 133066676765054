.logo-card {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.logo-photo {
    width: 137px;
    height: 125px;
    margin-right: 30px;
}

.logo-title {
    margin-top: 0;
    margin-bottom: 0;
    /* font-size: 36px; */
    color: inherit;
    padding-right: 190px;
}
.player-card {
    display: flex;
    align-items: center;
    border: 1px solid #ced4da;
    border-radius: 8px;
    padding: 15px;
    background-color: #f8f9fa;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px 0;
}

.player-photo {
    width: 80px; /* Adjust the size as needed */
    height: 80px; /* Adjust the size as needed */
    border-radius: 50%; /* Circular photo */
    margin-right: 15px;
}

.player-info {
    flex-grow: 1;
}
.additional-info {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.5rem; /* Increased font size */
    padding-right: 41px;
}

.additional-info p {
    margin: 8px 0; /* More space between lines */
    color: #495057;
}

.additional-info strong {
    font-weight: bold; /* Emphasize the labels */
    color: #343a40; /* Darker color for contrast */
}

.date-range {
    font-weight: bold;
    color: #007bff; /* Highlighting the date range */
}


.form-label {
    min-width: 120px;
    color: #495057;
    font-size: 16px;
    font-weight: bold;
    margin-right: 1rem; /* Adjust for spacing between label and input */
  }
  
  .input-field {
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #ced4da;
    height: 45px; /* Increased height */
    font-size: 16px; /* Adjusted font size */
  }
  
  .inputAssessment .form-onboarding .form-control {
      height: 30px !important;
    }

/* table */
.matrix-container {
    margin-top: 70px;
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    background-color: #f8f9fa;
}

.matrix-title {
    text-align: center;
    margin-bottom: 15px;
}

.form-matrix-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #dee2e6;
    text-align: center;
}

.form-matrix-th {
    border: none;
}

.form-matrix-header-tr {
    background-color: #e9ecef;
}

.form-matrix-headers {
    position: relative;
    border: 1px solid #dee2e6;
    text-align: center;
}

.editor-container {
    display: inline-block;
    
}

.inlineEditor {
    padding: 8px;
    border-radius: 4px;
}

.form-matrix-value-tr {
    border: 1px solid #dee2e6;
    
}

.form-matrix-row-headers {
    border: 1px solid #dee2e6;
    background-color: #e9ecef;

}

.form-radio {
    margin-left: 5px;
}

.form-textarea {
    border: 2px solid #ccc; /* Gray border */
    border-radius: 4px; /* Slightly rounded corners */
    padding: 10px; /* Padding inside the textarea */
    font-size: 16px; /* Font size */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
    resize: none; /* Prevent resizing */
  }
  
  .form-textarea:focus {
    border-color: #007bff; /* Change border color on focus */
    outline: none; /* Remove default outline */
  }
  
  .avatar__holder {
    width: 130px;
    height: 30px;
}

.center-form {
    display: flex;
    flex-direction: column;
    text-align: center; /* To center text like the label */
}

.rectangular-input {
    border: 1px solid #0000002e;
    border-radius: 0;
    padding: 10px;
    width: 27%;
    max-width: 400px;
    box-sizing: border-box;
    background-color: #fff;
    appearance: none;
    margin-left: 315px;
    border-radius: 5%;
}